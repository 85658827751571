import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Banque en ligne - le comparatif avec BankBank</title>
                <meta name="description" content="
             Le marché bancaire n'a jamais connu autant d'offres. Choisir la meilleure banque en ligne en 2020 sera clé à la bonne tenue de votre porte-monnaie." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Banques en ligne – Le comparatif</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Banques en ligne – Le comparatif</Title>
			<StaticImage
		  	src='../../images/banque-en-ligne-comparatif.png'
			width={1200}
			alt='Banque en ligne comparatif'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
			<p>Si les banques en ligne reviennent deux à trois fois moins cher que les banques traditionnelles, il n’en est pas moins que celles-ci ne se valent pas toutes. Si la gratuité de certains services est semblable chez la plupart d’entre elles, comparer les banques en ligne peut se faire à partir d’autres critères notamment les conditions d’accès, les cartes de paiement proposées, le service client, les gammes de produits ou les services disponibles.
			</p>
<h2>Les conditions d’ouverture de compte</h2>
<h3>Les conditions de revenus des banques en ligne</h3>
<p>Les conditions de revenus minimums exigées par les banques en ligne varient de 0 € à 1 600 €.</p>

<p>Les plus accessibles sont Boursorama et Monabanq en proposant des offres sans conditions de ressources mais qui nécessite en contrepartie une cotisation mensuelle. Pour la première, l’offre Welcome à 1,50€/mois au lieu des 1000€ net/mois requis et pour la seconde une diversité d’offres à 2€, 5€ ou 6€/mois selon la carte et les services souhaités. Chez Hello Bank, il est possible d’ouvrir un compte et de bénéficier d’une carte Visa Electron avec des revenus mensuels inférieurs à 1 000 €. Si le client souhaite un autre type de carte, les conditions de revenus minimums commencent à 1000 €/mois.
</p><p>
Viennent ensuite Fortuneo et ING Direct qui exigent 1 200 €/mois. Et pour finir, BforBank avec 1 600 € de revenus net mensuels.
</p>
<h3>Le versement initial d’activation du compte</h3>
<p>Pour bénéficier de son compte en ligne, il est nécessaire de procéder à son activation grâce au versement d’un montant sur le compte. Monabanq est la seule banque à exiger 150 € tandis que le montant s’élève à 300 € pour les autres banques.
</p>
<h2>Les produits et services des banques en ligne</h2>
<h3>Les cartes bancaires à disposition</h3>
<p>Selon les établissements et leur stratégie commerciale, les gammes de carte peuvent sensiblement varier. Ainsi, seuls Hello Bank et Monabanq disposent de cartes à contrôle systématique de solde et les cartes Prestiges comme la Visa Infinite et la World Elite Mastercard sont uniquement disponibles chez BforBank et Fortuneo.
</p><p>Les cartes classiques Visa et Mastercard sont quant à elles plus répandues puisqu’elles existent chez Boursorama, Hello Bank, Monabanq et Fortuneo. De la même façon, les cartes haut de gamme Gold Mastercard ou Visa Premier se trouvent chez Boursorama, Hello Bank, BforBank, Monabanq, ING Direct et Fortuneo.
</p>
<h3>Service de dépôt d’espèces et de chèque</h3>
<p>Le service de dépôt d’espèces est uniquement disponible chez Hello Bank et Monabanq qui proposent de faire le dépôt dans les guichets automatiques de leurs filiales respectives BNP Paribas et le CIC.
</p><p>
Quant au dépôt de chèque par courrier, il est possible chez toutes les banques en ligne grâce aux enveloppes mises à disposition par les établissements. BforBank et Monabanq offrent la possibilité de le faire numériquement
</p>
<h3>La qualité et la disponibilité du service clientèle</h3>
<p>Si la qualité du service client se vaut dans chaque établissement en matière de rapidité et d’efficacité, les modes de communication et les disponibilités peuvent quant à elles varier. ING Direct et Hello Bank se démarque notamment par la diversité des moyens de contact et les plages horaires étendues.
</p><p>
Chez la première il est possible de prendre contact par téléphone ou email, de 8h à 21h du lundi au samedi et il est également possible de se rendre aux cafés ING Direct à Paris et à Lyon pour y rencontrer des conseillers. Chez la seconde, le client peut s’adresser au service client par téléphone; email, Facebook, Twitter de 8h à 22h du lundi au vendredi et de 8h à 18h le samedi ou en se rendant dans les agences BNP Paribas.
</p><p>
Boursorama n’est pas en reste avec des modes de communication par téléphone ou email de 8h à 22h du lundi au vendredi et de 8h45 à 16h20 le samedi.
</p>
<h3>L’ergonomie du site internet et de l’application mobile</h3>
<p>Étant des banques entièrement en ligne, l’ergonomie du site et de l‘application occupe un critère de choix dans la sélection de l’établissement. ING Direct et Fortuneo sont particulièrement compétentes en la matière.
</p>
<h3>Les produits proposés</h3>
<p>En matière d’épargne, le livret A ou le LDD sont proposés par toutes les banques en ligne. Cependant, l’on retrouve uniquement le PEL ou le CEL chez Boursorama et Hello Bank. Les livrets bancaires quant à eux bénéficient d’un taux de 0,60 % chez Hello Bank, 0,40 % chez Fortuneo et Monabanq, 0,30 % chez BforBank et Boursorama. Actuellement, il est possible de bénéficier d’offres avec un taux à 2% chez Fortuneo, 2% sur deux mois chez ING Direct, 3% chez Hello Bank et 3% et 40 € offerts chez BforBank.
</p><p>
En ce qui concerne les produits boursiers, ceux-ci sont avantageux chez toutes les banques en ligne. Boursorama et Hello Bank se démarquent par leur accessibilité à des profils d’investisseurs débutants.
</p>
<h2>Les offres promotionnelles</h2>
<p>Comme souvent, les banques en ligne mettent en place différentes offres promotionnelles variables d’un établissement à un autre.
</p><p>
Retrouvez le comparatif des offres promos.</p>
			</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
